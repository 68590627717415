@import '../../styles/global';

.app{
    height: fit-content;

    &__main{
        display: flex;
        height: fit-content;
        min-height: 95vh;
        // align-items: center;
    }
}