@import '../../styles/global';

.newvideo{
    width: 100%;
    min-height: 100%;
    background-color: $themeSlateOffsetPrimary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vw 10vw 0;

    &__overview{
        width: 100%;
        display: flex;
        flex-direction: column;

        &-border{
            background-color: $themeSlateContrastTertiary;
            width: 10vw;
            height: 8px;
            border-radius: 24px;
            position: absolute;
        }
        &-heading{
            margin: 16px 0;
        }
        &-description{
            margin: 0;
        }
    }
}

.launch{
    height: 100%;
    width: 100%;

    &__firstinit{
        font-weight: 600;
        background-color: #FFF;
        padding: 16px;
        margin-top: 0;
        border-radius: 24px;
        box-shadow: 4px 4px 8px $themeSlatePrimary;
    }

    &-emph{
        color: $themeSlateContrastTertiary;
        font-weight: 800;
        text-decoration: none;
        
        &.--heading{
            color: $themeSlatePrimary;
        }
    }

    &__start{
        width: 100%;
        display: flex;
        justify-content: center;

        &-button{
            background-color: $themeSlateContrastTertiary;
            color: $themeSlateOffsetPrimary;
            border-radius: 4px;
            border-color: $themeSlateContrastTertiary;
            padding: 2px 8px;
            
            &:hover{
                cursor: pointer;
            }
        }
    }

    &__overview{
        &-desc{
            font-weight: 600;
            padding-left: 0;

            &-consider{
                font-weight: 400;
                margin: 8px 0 0 24px;
                padding: 0;
                font-size: .9375rem;

                &> ul{
                    padding-left: 26px;
                }

                &-point{
                    list-style: none;
                    margin: 8px 0;
                    font-size: .875rem;
                    line-height: 1.4rem;
                    align-content: center;
                                        
                    &-icon{
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }
}