@import '../../styles/global';

.about{
    width: 100%;
    height: 95vh;
    background-color: $themeSlateOffsetPrimary;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 10vw;


    &__heading{
        color: $themeSlateContrastTertiary;
    }

    &__emph {
        color: $themeSlateContrastTertiary;
        font-weight: 800;
    }
}