@import '../../styles/global';

@keyframes record-pulse {
  0% {transform: scale(.9);}
  50% {transform: scale(.7);}
  100% {transform: scale(.9);}
}

.studio{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  @include desktop-adapt{
      flex-direction: row;
  }

  &__container{
    height: 50%;
    width: 100%;

    @include desktop-adapt{
        height: 100%;
        width: 65%;
    }

    &-recorder{
      display: flex;
      flex-direction: column;

      &-wrapper{
        position: relative;
        width: 90%;

        &-preview{
          width: 100%;
          background-color: $themeSlateSecondary;
        }
      }

      &-actions{
        display: flex;
      }

      &-devices{
        width: 90%;
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;

        & > div{
          display: flex;
          flex-direction: column;
          margin-right: 16px;

          & > label{
            font-size: .9125rem;
            font-weight: 600;
          }

          & > select{
            width: 100%;
            & > option{
              width: fit-content;
            }
          }
        }

        &.--disable{
          display: none;
        }
      }
      &-init{
        width: 90%;
        display: flex;
        justify-content: center;
        margin: 16px 0;

        &-start{
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFF;
          border-radius: 24px;
          border: none;
          box-shadow: 4px 4px 4px $themeSlatePrimary;
          font-weight: 600;

          &:hover{
            cursor: pointer;
            color: $themeSlateContrastTertiary;
          }

          &-icon{
            position: relative;
            top: 1px;
            animation: record-pulse 2s linear infinite;
          }
          &.--active{
            display: none;
          }
        }
        &-end{
          @extend .studio__container-recorder-init-start;

          &.--active{
            display: none;
          }
        }
      }
    }

    &-upload-progress{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-label{
        margin-right: 8px;

        &.--done{
          color: $themeSlateContrastTertiary;
        }
      }

      &-bar{
        width: 70%;
        height: 10px;

        &[value]{
          -webkit-appearance: none;
          appearance: none;

          &::-webkit-progress-bar{
            background-color: #FFF;
            border-radius: 24px;
            box-shadow: 0 2px 4px $themeSlatePrimary inset;
          }

          &::-webkit-progress-value{
            background-color: $themeSlateContrastTertiary;
            border-radius: 24px;
            box-shadow: 2px 0 2px $themeSlatePrimary;
          }
        }
      }
    }
  }
  &__modal{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    background-color: rgba(38, 38, 38,0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper{
      width: 50%;
      height: fit-content;
      background-color: #FFF;
      padding: 1vw 3vw 2vw;
      border-radius: 24px;

      &-heading{
        color: $themeSlateContrastTertiary;
      }

      &-buttons{
        width: 100%;
        display: flex;
        justify-content: space-around;

        & > button {
          border-radius: 4px;
          padding: 6px 12px;
          box-shadow: 2px 2px 4px $themeSlatePrimary;
          font-weight: 600;

          &:hover{
            cursor: pointer;
          }
        }

        &-cancel{
          background-color: $themeSlateOffsetPrimary;
          color: $themeSlateContrastTertiary;
          border-color: $themeSlateOffsetPrimary;
        }

        &-start{
          background-color: $themeSlateContrastTertiary;
          color: $themeSlateOffsetPrimary;
          border-color: $themeSlateContrastTertiary;
        }
      }
    }
  }

  &__questions{
    height: 50%;
    width: 100%;

    @include desktop-adapt{
      height: 100%;
      width: 35%;
    }

    &-heading{
      margin: 0;
      color: $themeSlateContrastTertiary;
    }

    &-title{
      display: flex;
      background-color: #FFF;
      border-radius: 24px;
      box-shadow: 4px 4px 4px $themeSlatePrimary;
      justify-content: center;
      align-items: center;
      margin: 8px 0;
      height: 48px;

      &.--add{
        height: 32px;
        position: relative;

        & img, p{
          opacity: 1;
        }
        &:hover{
          cursor: auto;
        }
      }

      & img, p{
        opacity: 0.6;
      }

      &-editicon{
          position: absolute;
          right: 8px;

          &:hover{
            cursor: pointer;
          }
      }

      &-content{
        font-size: .75rem;

        &-edit{
          outline: none;
          border: none;
          background-color: $themeSlateOffsetPrimary;
          width: 69%;
          font-size: .875rem;

          &:focus{
            border-bottom: 1px inset $themeSlatePrimary;
          }
        }

        &.--add{
          text-transform: uppercase;
          color: $themeSlateContrastTertiary;
          font-weight: 800;
          font-size: 1rem;
          letter-spacing: .1rem;
        }
      }

      &:hover{
        cursor: pointer;
      }

      &:hover *{
        opacity: 1;
      }
    }

    &-desc{
      font-size: .875rem;
      font-weight: 600;
    }

    &-emph{
      color: $themeSlateContrastTertiary;
      font-weight: 800;
    }

    &-prompt{
      width: 100%;
      height: 48px;
      background-color: #FFF;
      box-shadow: 4px 4px 4px $themeSlatePrimary;
      border-radius: 24px;
      display: flex;
      align-items: center;
      padding: 4px 0;
      margin: 16px 0;
      
      &-number{
        margin: 0 16px 0 0;
        background-color: $themeSlateContrastTertiary;
        color: #FFF;
        height: 48px;
        min-width: 40px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
      }

      &.--add{
        max-width: 60vw;
        margin: 0 auto;

        & img, p{
          opacity: 0.6;
          font-family: "Raleway";
          font-weight: 600;
        }

        &:hover{
          cursor: pointer;
        }

        &:hover *{
          opacity: 1;
        }
      }

      &-icon{
        height: 32px;
        margin: 0 0 0 30%;
      }

      &-question{
        font-size: .75rem;
        width: 69%;

        &-edit{
          outline: none;
          border: none;
          background-color: $themeSlateOffsetPrimary;
          width: 69%;
          font-size: .75rem;

          &:focus{
            border-bottom: 1px inset $themeSlatePrimary;
          }
        }
      }
      &-edit{
        height: 20px;
        width: 20px;

        &:hover{
            cursor: pointer;
        }
      }
      &-delete{
        height: 20px;
        width: 20px;

        &:hover{
            cursor: pointer;
        }
      }

      &.--inactive{
        pointer-events: none;
        opacity: 0.6;
      }

      &:hover{
        cursor: pointer;
      }
    }
  }
}