@import '../../styles/global';


.userprofile{
    width: 100%;
    height: 95vh;
    background-color: $themeSlateOffsetPrimary;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 10vw;

    &__container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        &-border{
            background-color: $themeSlateContrastTertiary;
            width: 10vw;
            height: 8px;
            border-radius: 24px;
            position: absolute;
        }

        &-profile{
            display: flex;
            flex-direction: column;

            &-card{
                width: 248px;
                height: fit-content;
                background-color: #FFF;
                border-radius: 24px;
                margin: 16px 0;
                box-shadow: 4px 4px 4px $themeSlatePrimary;
                position: relative;
    
                &-image{
                    width: 100%;
                    background-color: $themeSlateOffsetSecondary;
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    height: 240px;
                    object-fit: cover;
                    object-position: top;
                }

                &-upload{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    width: 240px;
                    height: 240px;
                    background-color: rgba(38, 38, 38, 0.6);
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    opacity: 0.6;

                    &-mgr{
                        display: none;
                    }

                    &-icon{
                        height: 80px;
                    }
                    &-desc{
                        color: #FFF;
                        font-weight: 600;
                        margin: 0;
                        user-select: none;
                    }

                    &:hover{
                        opacity: 0.8;
                        cursor: pointer;
                    }
                }
    
                &-details{
                    padding: 16px 16px 0;
                    background-color: #FFF;
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    position: relative;
                    bottom: 24px;
    
                    &-data{
                        margin: 8px 0;
                        font-size: .875rem;
                        display: flex;
                        align-items: center;
    
                        &-icon{
                            margin-right: 4px;
                            height: 20px;
                        }
                    }
                    &-value{
                        font-weight: 400;
                        font-size: .85rem;
                        margin: 0;
                        word-wrap: break-word;
                    }
                    
                    & > h2{
                        color: $themeSlateContrastTertiary;
                        font-size: 1.5rem;
                        padding-bottom: 8px;
                        border-bottom: 2px solid $themeSlatePrimary;
                    }

                    &-edit{
                        &-name{
                            margin: 4px 0;
                            outline: none;
                            border: none;
                            background-color: $themeSlateOffsetPrimary;

                            &:focus{
                                border-bottom: 1px inset $themeSlatePrimary;
                            }
                        }
                        &-email{
                            outline: none;
                            border: none;
                            background-color: $themeSlateOffsetPrimary;

                            &:focus{
                                border-bottom: 1px inset $themeSlatePrimary;
                            }
                        }
                        &-phone{
                            outline: none;
                            border: none;
                            background-color: $themeSlateOffsetPrimary;

                            &:focus{
                                border-bottom: 1px inset $themeSlatePrimary;
                            }
                        }
                    }
                }
            }
            &-editsave{
                background-color: $themeSlateContrastTertiary;
                border-radius: 24px;
                height: 24px;
                padding: 16px;
                color: $themeSlateOffsetPrimary;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Raleway";
                font-weight: 600;
                user-select: none;
                box-shadow: 4px 4px 4px $themeSlatePrimary;
                margin: 2px 0;

                &:hover{
                    cursor: pointer;
                }
            }
            &-cancel{
                @extend .userprofile__container-profile-editsave;
                background-color: $themeSlateOffsetPrimary;
                color: $themeSlateContrastTertiary;
            }
        }

        &-content{
            width: 100%;
            min-height: 100%;
            height: fit-content;
            padding: 16px 0 16px 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-wrapper{
                width: 100%;
                background-color: #FFF;
                border-radius: 24px;
                padding: 16px;
                box-shadow: 4px 4px 4px $themeSlatePrimary;

                &-titles{
                    display: flex;

                    &-heading{
                        width: 50%;
                        padding: 8px 24px;
                        margin: 0;
                        color: $themeSlatePrimary;
                        font-weight: 400;
                        user-select: none;

                        &:nth-of-type(1){
                            border-right: 2px solid $themeSlatePrimary;
                            text-align: end;
                        }

                        &.--active{
                            color: $themeSlateContrastTertiary;
                            font-weight: 600;
                        }

                        &:hover{
                            cursor: pointer;
                        }
                    }
                }

                &-detail{
                    margin: 8px 0;
                    width: 100%;
                    
                    &-heading{
                        margin: 8px 0;
                        border-bottom: 1px inset $themeSlateContrastTertiary;
                        padding-bottom: 2px;

                        &-edit{
                            margin: 16px 0 8px;
                            width: 248px;
                            outline: none;
                            border: none;
                            background-color: $themeSlateOffsetPrimary;
                            
                            &:focus{
                                border-bottom: 1px inset $themeSlatePrimary;
                            }
                        }
                    }
                    &-values{
                        margin: 0;

                        &-edit{
                            width: 100%;
                            overflow: scroll;
                            resize: vertical;
                            min-height: 80px;
                            height: 80px;
                            max-height: 120px;
                            outline: none;
                            background-color: $themeSlateOffsetPrimary;
                            border: none;

                            &:focus{
                                border: 1px inset $themeSlatePrimary;
                            }
                        }
                    }
                    &-construction{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &-edit{
                        display: flex;
                        flex-direction: column;

                        &-about{
                            width: 100%;
                            overflow: scroll;
                            resize: vertical;
                            min-height: 120px;
                            height: 120px;
                            max-height: 370px;
                            outline: none;
                            background-color: $themeSlateOffsetPrimary;
                            border: none;

                            &:focus{
                                border: 1px inset $themeSlatePrimary;
                            }
                        }
                    }
                }
            }

            &-uploads{
                display: flex;
                justify-content: center;
                
                &-stack{
                    display: flex;
                    width: fit-content;
                    align-items: flex-end;
                    height: 232px;
                    // padding: 0 0 0 52px;
                    // box-shadow: 4px 4px 8px $themeSlatePrimary;
                    border-radius: 24px;
                    // background-color: #FFF;
                    position: relative;

                    &-heading{
                        transform-origin: center;
                        // transform: rotate(-90deg);
                        height: fit-content;
                        width: 192px;
                        padding: 6px 12px;
                        margin: 0;
                        font-size: 1.25rem;
                        color: $themeSlateContrastTertiary;
                        text-align: center;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    &-card{
                        background-color: #FFF;
                        height: 192px;
                        width: 192px;
                        border-radius: 24px;
                        position: relative;
                        box-shadow: 4px 4px 8px $themeSlatePrimary;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        color: $themeSlatePrimary;

                        &:not(:last-child){
                            margin-right: -140px;
                        }
                        &:hover{
                            margin-right: 0px;
                            cursor: pointer;
                        }
                        &:hover:last-child > img{
                            opacity: 1;
                        }
                        &:hover:last-child > h4{
                            color: $themeSlatePrimary;
                        }

                        &-title{
                            transform-origin: center;
                            transform: rotate(-90deg);
                            height: 192px;
                            width: 192px;
                            padding: 6px 12px;
                            margin: 0;
                            color: $themeSlatePrimary;
                        }
                        &-icon{
                            height: 96px;
                            opacity: 0.6;
                        }
                        &-desc{
                            margin: 8px 8px 0;
                            color: #FFF;
                        }

                        &-details{
                            position: absolute;
                            top: 6px;
                            right: 12px;
                            display: flex;
                            flex-direction: column;

                            &-value{
                                margin: 0;
                                font-size: .875rem;
                                color: $themeSlateSecondary;

                                &.--delete{
                                    position: absolute;
                                    bottom: 6px;
                                    right: 12px;
                                    display: flex;
                                    align-items: center;
                                    color: $themeSlateContrastTertiary;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
                &-emph{
                    color: $themeSlateContrastTertiary;
                    font-weight: 600;
                }
            }
        }
    }
}