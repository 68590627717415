@import '../../styles/global';

@keyframes hoverjump {
    0% {transform: translateY(0)};
    25% {transform: translateY(-5px) scaleX(1.05); box-shadow: 0px 2.5px 5px};
    50% {transform: translateY(-8px) scaleX(1.15); box-shadow: 0px 5px 5px};
    75% {transform: translateY(-5px) scaleX(1.05); box-shadow: 0px 2.5px 5px};
    100% {transform: translateY(0px)};
}

.account{
    background-color: #FFF;
    position: absolute;
    top: 20vh;
    left: 35%;
    border-radius: 16px;
    width: 30vw;
    display: flex;
    justify-content: center;
    padding: 16px 8px;
    z-index: 2;
    box-shadow: 4px 4px 4px $themeSlatePrimary;

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 8px;
        position: relative;
        width: 100%;

        &-heading{
            color: $themeSlateContrastTertiary;
            margin-top: 32px;
        }

        &-type{
            display: flex;
            align-items: center;
            width: 80%;
            border-radius: 16px;
            box-shadow: inset -2px 0 2px;
            margin-bottom: 8px;

            &-toggle{
                border-radius: 16px;
                width: 50%;
                font-family: "Raleway";
                text-align: center;
                font-size: .8125rem;
                user-select: none;

                &--active{
                    background-color: $themeSlateContrastTertiary;
                    color:$themeSlateOffsetPrimary;
                    border-radius: 16px;
                    border: 2px solid $themeSlatePrimary;
                    width: 50%;
                    font-family: "Raleway";
                    text-align: center;
                    font-size: .8125rem;
                    font-weight: 600;
                    user-select: none;
                }
            }
            &:hover{
                cursor: pointer;
            }
        }

        &-form{
            display: flex;
            flex-direction: column;
            width: 100%;

            &-label{
                font-size: .75rem;
            }
            
            &-passcontainer{
                position: relative;
            }

            &-field{
                border: none;
                border-bottom: 1px solid $themeSlatePrimary;
                margin: 4px 0 8px;
                padding: 4px;
                font-size: .7125rem;
                outline: none;
                width: 100%;
            }

            &-passvis{
                align-self: flex-end;
                position: absolute;
                right: 4px;
                top: 4px;
                height: 16px;

                &:hover{
                    cursor: pointer;
                }
            }

            &-passval{
                color: red;
                font-size: .7125rem;
                margin: 0 0 8px;
            }

            &-submit{
                background-color: $themeSlatePrimary;
                color: $themeSlateOffsetPrimary;
                margin: 0 0 8px;
                border-radius: 8px;

                &:hover{
                    cursor: pointer;
                    background-color: $themeSlateContrastTertiary;
                    border-color: $themeSlateContrastTertiary
                }
            }
        }

        &-redirect{
            display: flex;
            font-size: .75rem;
            justify-content: space-between;
            width: 100%;
            color: $themeSlateContrastTertiary;
            font-weight: 600;

            & > p{
                margin: 2px 0;

                &:hover{
                    cursor: pointer;
                }
            }
        }

        &-or{
            display: flex;
            width: 100%;
            margin: 8px;
            & > span{
                margin: 0 4px;
                font-weight: 600;
            }
            & > hr{
                width: 100%;
                background-color: $themeSlatePrimary;
                border: none;
            }
        }

        &-alt{
            margin: 0;
            font-weight: 400;
            font-size: .75rem;
        }

        &-providers{
            width: 100%;
            display: flex;
            justify-content: center;
            & > img{
                height: 48px;
                width: 48px;
                margin: 8px;

                &:hover{
                    cursor: pointer;
                    animation: hoverjump .4s linear forwards;
                }
            }
        }
    }
}

.action{
    position: absolute;
    top: 0px;
    right: 0px;
}