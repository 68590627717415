@import '../../styles/global';

.how{
    width: 100%;
    height: 95vh;
    background-color: $themeSlateOffsetPrimary;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 10vw;

    &__heading{
        color: $themeSlateContrastTertiary
    }

    &__details-wrapper{
        display: flex;
        flex-direction: column;

        @include tablet-adapt{
            flex-direction: row;
        }
    }

    &__text{
        padding-bottom: 2vw;

        @include tablet-adapt{
            padding-right: 2vw;
        }
    }

    &__emph {
        color: $themeSlateContrastTertiary;
        font-weight: 800;
    }

    &__demo-video{
        width: 100%;
        max-width: 500px;
    }

    &__features-list{
        padding-left: 20px;
    }
}