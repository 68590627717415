@import "./variables";

// Typography Reset
html, body, input, textarea, button, ul, li {
    color: $themeSlatePrimary;
    font-family: 'Raleway', 'Ubuntu', 'Calibri', 'Verdana', sans-serif;
    // font-family: 'Zilla Slab', 'Josefin Slab', 'Perpetua', 'Garamond', serif;
    
    & h1, h2, h3, h4, h5, h6 {
        font-family: 'Raleway', 'Ubuntu', 'Calibri', 'Verdana', sans-serif;
    }
    /* #ToDo - Decide if you will utilize two fonts or not*/
}

// Fonts
// ---Zilla Slab (Body & Text)---

@font-face {
    font-family: 'Zilla Slab';
    src: url("../../assets/typography/Zilla_Slab/ZillaSlab-Light.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Zilla Slab';
    src: url("../../assets/typography/Zilla_Slab/ZillaSlab-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Zilla Slab';
    src: url("../../assets/typography/Zilla_Slab/ZillaSlab-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

// ---Josefin Slab (Body & Text - Back-Up Font)---

@font-face {
    font-family: 'Josefin Slab';
    src: url("../../assets/typography/Josefin_Slab/JosefinSlab-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Josefin Slab';
    src: url("../../assets/typography/Josefin_Slab/JosefinSlab-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Josefin Slab';
    src: url("../../assets/typography/Josefin_Slab/JosefinSlab-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

// ---Raleway (Heading)---

@font-face {
    font-family: 'Raleway';
    src: url("../../assets/typography/Raleway/Raleway-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url("../../assets/typography/Raleway/Raleway-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url("../../assets/typography/Raleway/Raleway-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url("../../assets/typography/Raleway/Raleway-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

// ---Ubuntu (Heading - Back-Up Font)---

@font-face {
    font-family: 'Ubuntu';
    src: url("../../assets/typography/Ubuntu/Ubuntu-Light.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url("../../assets/typography/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url("../../assets/typography/Ubuntu/Ubuntu-Medium.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url("../../assets/typography/Ubuntu/Ubuntu-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

// ---Architects Daughter (Flourish)---

@font-face {
    font-family: 'Architects Daughter';
    src: url("../../assets/typography/Architects_Daughter/ArchitectsDaughter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}