@import './partials/variables';
@import './partials/typography';
@import './partials/mixins';


// ---Global Reset 

html, body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    min-height: 100%;
    height: fit-content;      
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }