@import '../../styles/global';

@keyframes rotate-front {
    0% {transform: rotateY(0deg);}
    100% {transform: rotateY(180deg);}
}
@keyframes rotate-back {
    0% {transform: rotateY(180deg);}
    100% {transform: rotateY(360deg);}
}

@keyframes arrow-shift {
    0% {transform: translateX(0)}
    50% {transform: translateX(4px)}
    100% {transform: translateX(0)}
}

.landing{
    width: 100%;
    height: 95vh;
    background-color: $themeSlatePrimary;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container{
        &-heading{
            display: flex;
            position: relative;

            &-a{
                color: $themeSlateOffsetPrimary;
                margin-top: 0;
                backface-visibility: hidden;

                &-reverse{
                    position: absolute;
                    left: -5px;
                    top: 2px;
                    transform: rotateY(180deg);
                    backface-visibility: hidden;
                }
            }

            &-cumen{
                color: $themeSlateOffsetPrimary;
                margin-top: 0;
            }
        }

        &-pron{
            color: $themeSlateOffsetSecondary;
            width: 40vw;
            font-weight: 600;
            margin: 0 0 4px;
        }

        &-details{
            position: relative;
            display: flex;
            flex-direction: column;

            &-desc{
                color: $themeSlateOffsetSecondary;
                width: 40vw;
                font-weight: 600;
                margin: 0 0 4px;
                backface-visibility: hidden;

                &-reverse{
                    color: $themeSlateOffsetSecondary;
                    width: 40vw;
                    font-weight: 600;
                    margin: 0 0 4px;
                    position: absolute;
                    top: 0;
                    backface-visibility: hidden;
                    transform: rotateY(180deg);
                }
            }

            &-learn{
                color: $themeSlateOffsetSecondary;
                align-self: flex-end;
                text-decoration: none;
                font-weight: 600;
                display: flex;
                justify-content: center;
                position: relative;

                &-icon{
                    &-focus{
                        display: none;
                        margin-left: -12px;
                    }
                }
            }
        }
        &:hover .landing__container-heading-a-reverse{
            animation: rotate-back 1.5s linear forwards;
        }
        &:hover .landing__container-details-desc-reverse{
            animation: rotate-back 1.5s linear forwards;
        }
        &:hover .landing__container-details-desc{
            animation: rotate-front 1.5s linear forwards;
        }
        &:hover .landing__container-heading-a{
            animation: rotate-front 1.5s linear forwards;
        }
        &:hover .landing__container-details-learn{
            color: $themeSlateContrastTertiary;
        }
        &:hover .landing__container-details-learn > img{
            display: flex;
            animation: arrow-shift 1s linear infinite;
        }
        
    }

    &__emph{
        color: $themeSlateContrastTertiary;
    }
}