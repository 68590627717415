// Layout
$marginMobile: 16px;
$marginTablet: 24px;
$marginDesktop: 48px;

// Themes
// Slate
$themeSlatePrimary:#262626;
$themeSlateSecondary:#3f3f3f;
$themeSlateOffsetPrimary:#f5f5f5;
$themeSlateOffsetSecondary:#dcdcdc;
// Slate Contrast
$themeSlateContrastPrimary:#ff5a09;
$themeSlateContrastSecondary:#7acfd6;
$themeSlateContrastTertiary:#4cb69f;
$themeSlateContrastQuaternary:#dcae1d;
$themeSlateContrastQuinary:#c1432e;